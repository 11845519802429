<template>
  <div v-if="parentId" class="main-home">
    {{$l("login.welcome","欢迎访问梯智物联网平台")}}
  </div>
  <div v-else class="main-home">
    {{$l("login.welcome","欢迎访问梯智物联网平台")}}
    <!--    <div>-->
    <!--      选择公司-->
    <!--      <el-button v-for="item in list" :key="item.id" @click="changeCompanyId(item)">{{item.name}}</el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
  import loginUtil from "@/util/loginUtil";

  export default {
    name: "Home",
    data() {
      return {
        companyId: loginUtil.getCompanyCode(),
        list:[],
      };
    },
    mounted() {
      if (loginUtil.getUserInfo().employee&&!loginUtil.getUserInfo().employee.parentId) {
        this.getCompany();
      }
    },
    methods:{
      getCompany() {
        this.$http.get("/organization/company/page").then(res=>{
          this.list=res.records;
        });
      },
      changeCompanyId(item) {
        const userInfo=JSON.parse(localStorage.getItem("hold_user_info"));
        userInfo.employee.companyId = item.id;
        userInfo.employee.companyName = item.name;
        localStorage.setItem("hold_user_info",JSON.stringify(userInfo));
        this.$message.success("切换成功，将以"+item.name+"身份进行查询");
        location.reload(true);
      },
    },

  };
</script>

<style lang="scss" scoped>
  .main-home {
    text-align: center;
    font-size: 20px;
  }
</style>
